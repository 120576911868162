<script setup>

</script>

<template>
  <div>
    <h1>Contact</h1>
    <p>For any inquiries, please contact me at <a href="mailto:inquiry@leaguestat.tech"> my email </a></p>
  </div>
</template>

<style scoped>

</style>