<script setup>

</script>

<template>
  <div>
    <h1>About</h1>
    <p>I designed this website as a fun project for me and my friends to compare our ingame Stats</p>
  </div>
</template>

<style scoped>

</style>